@use '../../reference';

$-min-width: 30rem;

.rollup {
  --link-color: #{reference.$color-grey-500};
  --padding: 0.5rem;

  align-items: flex-start;
  border-radius: calc(reference.$border-radius + var(--padding));
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
  padding: var(--padding);
  position: relative;
  margin-inline-start: -0.5rem;
  text-decoration: none;
  transition: box-shadow 125ms ease-in-out;

  @container (min-width: #{$-min-width}) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  &:focus-within,
  &:hover {
    --link-color: #{reference.$color-primary};

    box-shadow: reference.$box-shadow-1;
  }
}

.draft {
  &::after {
    content: 'Draft';
    font-size: 62px;
    font-weight: bold;
    inset-block-start: 4rem;
    inset-inline-start: 3rem;
    opacity: 0.5;
    position: absolute;
    pointer-events: none;
    text-transform: uppercase;
    transform: rotate(-35deg);
    z-index: 10;
  }
}

.communitySubmission {
  position: absolute;
  inset-block-start: 1rem;
  inset-inline-end: 1rem;
  z-index: 2;

  @container (min-width: #{$-min-width}) {
    display: none;
  }
}

.picture {
  --padding: 1.5rem;

  align-items: center;
  aspect-ratio: 304 / 190;
  background-color: var(--background-color);
  border-radius: reference.$border-radius;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: var(--padding);
  position: relative;
  transition: background-color 250ms ease-in-out;
  width: 100%;

  @container (min-width: #{$-min-width}) {
    aspect-ratio: 304 / 298;
  }

  img {
    height: calc(100% - 2 * var(--padding));
    object-fit: contain;
    object-position: center center;
    position: absolute;
    width: calc(100% - 2 * var(--padding));
    z-index: 2;
  }
}

.content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  text-wrap: balance;
  row-gap: 0.5rem;

  @container (min-width: #{$-min-width}) {
    padding-block: 0.5rem;
  }
}

.link {
  @include reference.type-heading-5;

  color: var(--link-color);
  margin-block-end: 0.5rem;
  margin-block-start: 0;
  text-decoration: none;

  @container (min-width: #{$-min-width}) {
    @include reference.type-heading-4;

    color: var(--link-color);
  }
}

.description {
  margin-block-start: 0;
  margin-block-end: 0.5rem;
}
