@use '../../reference';

.hero {
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  text-align: center;

  h1 {
    margin-block: 0;
    max-width: clamp(25rem, 40vw, 50rem);
    text-wrap: balance;
  }

  p {
    margin-block: 0;
  }

  img {
    height: auto;
    width: 100vw;
  }
}
