@use '../../reference';

.button {
  background-color: transparent;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  flex: 1;
  font-size: reference.$font-size-filter;
  padding-inline: 1.625rem;
  text-align: left;
  transition: all 125ms ease-in-out;

  &[data-pressed='true'] {
    background-color: reference.$color-white;
    box-shadow: reference.$box-shadow-1;
  }

  &:hover {
    box-shadow: reference.$box-shadow-1;
  }

  &:active {
    background-color: reference.$color-grey-100;
  }

  // Goals
  &:first-child {
    flex: 1.35;
  }
}

.selection {
  color: reference.$color-primary;
  font-weight: reference.$font-weight-bold;
}

.dialog {
  outline: 0;
}

.popover {
  background-color: reference.$color-white;
  box-shadow: reference.$box-shadow-1;
  border-radius: reference.$border-radius;
  padding: 1.5rem;
}

.checkboxGroup {
  display: grid;
  gap: 1rem;

  &:has(> :nth-child(6)) {
    grid-template-columns: repeat(2, 1fr);

    .legend {
      grid-column: span 2;
    }
  }
}

.legend {
  font-weight: reference.$font-weight-semibold;
}

.label {
  align-items: center;
  border-radius: 3px;
  display: inline-flex;
  column-gap: 0.5rem;
  cursor: pointer;
  font-size: 0.875rem;

  &[data-focus-visible='true'] {
    outline: 2px solid;
    outline-offset: 3px;
  }
}

.checkbox {
  --size: 1rem;

  align-items: center;
  border-radius: 3px;
  border: 1px solid #c2c2c2;
  color: reference.$color-white;
  display: flex;
  height: var(--size);
  justify-content: center;
  overflow: hidden;
  width: var(--size);

  [data-selected='true'] & {
    background-color: reference.$color-primary;
    border-color: reference.$color-primary;
  }
}
