@use '../../reference';

.container {
  --padding: 1.5rem;

  background-color: reference.$color-grey-100;
  border-radius: reference.$border-radius;
  box-sizing: border-box;
  column-gap: 1.5rem;
  display: grid;
  padding: var(--padding);
  row-gap: 1rem;

  @include reference.dark-mode {
    background-color: reference.$color-grey-200;
  }

  @container (min-width: 44rem) {
    --padding: 2.5rem;

    grid-template-columns: min-content 1fr;
  }
}

.image {
  --size: 2.5rem;

  height: var(--size);
  width: var(--size);

  @container (min-width: 44rem) {
    --size: 4rem;
  }
}

.heading {
  @include reference.type-heading-4;

  margin-block: 0;
}
