@use '../../reference';

.numberTicker {
  align-items: center;

  @include reference.flex-column;
}

.metric {
  font-size: reference.$font-size-body-large;
}

.value {
  color: reference.$color-primary;
  font-size: reference.$font-size-huge;
  font-weight: reference.$font-weight-bold;
  line-height: 1;
}
