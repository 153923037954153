@use '../../reference';

.card {
  border: 1px solid;
  border-color: reference.$color-grey-200;
  border-radius: reference.$border-radius;
  box-sizing: border-box;
  display: block;
  height: 100%;
  padding: 1.5rem;
  position: relative;
  text-decoration: none;
  text-wrap: balance;
  transition: box-shadow 125ms ease-in-out;

  &[href]:focus-within,
  &[href]:hover {
    box-shadow: reference.$box-shadow-1;
  }

  h3 {
    @include reference.type-heading-5;

    margin-block: 0.5rem;
  }

  > *:first-child {
    margin-block-start: 0;
  }

  > *:last-child {
    margin-block-end: 0;
  }
}

.fuchsia,
.aqua,
.new-leaf,
.tangerine,
.red {
  border-block-end: 2px solid;
  border-end-start-radius: 0;
  border-end-end-radius: 0;
}

.fuchsia {
  border-block-end-color: reference.$color-fuchsia;
}

.aqua {
  border-block-end-color: reference.$color-aqua;
}

.new-leaf {
  border-block-end-color: reference.$color-new-leaf;
}

.tangerine {
  border-block-end-color: reference.$color-tangerine;
}

.red {
  border-block-end-color: reference.$color-primary;
}
