@use '../../reference';

.container {
  align-items: flex-start;
  display: flex;
  gap: 0.5rem;
}

.label {
  @include reference.type-tag;

  margin-block-start: 0.25rem; // Vertical align with first tab
  width: 3.5rem;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
