@use '../../reference';

$width: 44rem;

.article {
  --row-gap: 1.5rem;
  --width: #{$width};

  container-type: inline-size;
  margin-inline: auto;
  max-width: calc(100vw - 4rem);
  width: var(--width);

  @container (min-width: #{reference.$breakpoint-sm}) {
    --row-gap: 2.5rem;
  }

  .communitySubmission {
    color: reference.$color-aqua;
    display: flex;
    justify-content: center;
    margin-block-end: -1rem;
  }

  h1 {
    text-align: center;
    text-wrap: balance;
  }

  .description {
    font-size: reference.$font-size-body-large;
    font-weight: reference.$font-weight-light;
    margin-block-end: 4rem;
    text-align: center;
    text-wrap: balance;
  }

  a:not([class]) {
    --color: #{reference.$color-primary};

    text-decoration-skip-ink: auto;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;

    &:focus,
    &:hover {
      --color: #{reference.$color-primary-dark};

      text-decoration: none;
    }
  }

  ol {
    padding-inline-start: 0;
  }

  ol > li {
    --marker-font-size: 1rem;
    --marker-size: 2rem;

    counter-increment: list-item;
    list-style-type: none;
    margin-block: var(--row-gap);
    min-height: var(--marker-size);
    padding-block-start: 0.25rem;
    padding-inline-start: calc(var(--marker-size) + 1rem);
    position: relative;

    @container (min-width: #{reference.$breakpoint-sm}) {
      --marker-font-size: 1.5rem;
      --marker-size: 3rem;

      padding-block-start: 0.75rem;
      padding-inline-start: calc(var(--marker-size) + 2rem);
    }

    &::before {
      align-items: center;
      background-color: reference.$color-primary;
      border-radius: 50%;
      color: reference.$color-white;
      content: counter(list-item);
      display: flex;
      font-size: var(--marker-font-size);
      font-weight: reference.$font-weight-bold;
      height: var(--marker-size);
      inset-block-start: 0;
      inset-inline-start: 0;
      justify-content: center;
      line-height: var(--marker-size);
      position: absolute;
      width: var(--marker-size);
    }

    > *:first-child {
      margin-block-start: 0;
    }

    > *:last-child {
      margin-block-end: 0;
    }
  }

  ol ul {
    list-style-type: disc;
  }

  ul {
    padding-inline-start: 1rem;
  }

  ul li {
    margin-block: 1rem;
  }

  ul li::marker {
    color: reference.$color-primary;
  }

  hr {
    border-block: 1px solid reference.$color-grey-200;
    border-inline: 0;
    margin-block: calc(var(--row-gap) + 1rem);
  }

  p:has(img:not([class]):only-child) {
    display: flex;
    justify-content: center;

    img:not([class]) {
      --margin-inline: calc(var(--main-margin-inline) * -1);

      max-width: 56rem;
      margin-inline: var(--margin-inline);
      width: 100vw !important;

      @container (min-width: #{$width}) {
        border-radius: reference.$border-radius;
      }
    }
  }
}

.checklist {
  background-color: reference.$color-grey-100;
  border-radius: reference.$border-radius;
  margin-block: 2rem;
  padding: 2rem;

  > *:first-child {
    margin-block-start: 0;
  }

  > *:last-child {
    margin-block-end: 0;
  }

  h3 {
    @include reference.type-heading-4;
  }

  ul {
    list-style: none;
    margin-inline: 0;
    padding-inline: 0;
  }

  li {
    display: flex;
    gap: reference.$space-2xs;

    &:last-child {
      margin-block-end: 0;
    }

    svg {
      flex-shrink: 0;
    }
  }
}

.submissionEmailPicture {
  display: flex;
  justify-content: center;

  img {
    height: auto;
    max-width: min(100vw, 1012px);
  }
}
