@use '../../reference';

.header {
  --height: clamp(16rem, 20vw, 20rem);

  height: calc(var(--height) + 4rem);
  margin-block-start: calc(var(--main-margin-block-start) * -1);
  margin-block-end: 3rem;
  position: relative;
}

.picture {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: calc(var(--height) + 4rem);
  justify-content: center;
  left: min(calc((100vw - 44rem) / -2), -2rem);
  overflow: hidden;
  padding-block-start: 2rem;
  position: absolute;
  width: 100vw;

  svg {
    color: var(--background-color);
    position: absolute;
    inset-block-end: -1px;
    inset-inline-start: 0;
  }
}

.image {
  height: calc(var(--height) + 4rem);
  max-width: 36rem;
  object-fit: contain;
  object-position: 0 5.5vw;
  pointer-events: none;
  width: auto;

  @media (min-width: reference.$breakpoint-lg) {
    height: calc(var(--height) + 8rem);
  }
}
