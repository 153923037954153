@use '../../reference';

.container {
  left: 50%;
  margin-inline: -50vw;
  overflow: hidden;
  padding-block: reference.$space-s;
  position: relative;
  right: 50%;
  width: 100vw;
}

.content {
  align-items: flex-end;
  display: flex;
  gap: reference.$space-s;

  & > * {
    box-shadow: reference.$box-shadow-1;
    min-width: min(38rem, 90vw);
  }
}
