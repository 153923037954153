@use '../../reference';

.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  margin-block: 2.5rem;
  padding-block: 1.5rem;
  row-gap: 1rem;

  @media (min-width: 60rem) {
    border-radius: reference.$border-radius;
    border: 1px solid reference.$color-grey-200;
    box-shadow: reference.$box-shadow-1;
    grid-template-columns: repeat(6, 1fr);
    margin-block: 3.5rem;
    margin-inline: max(calc(-100vw / 2 + 44rem / 2 + 4rem), -19rem);
    padding-inline: 1.5rem;
  }

  b {
    @include reference.type-tag;

    display: block;
    text-transform: uppercase;
  }
}

.metadata {
  column-gap: 0.5rem;
  display: grid;
  font-size: 1rem;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content;
  row-gap: 0.25rem;

  svg {
    color: reference.$color-primary;
    grid-row-start: span 2;
    margin-block-start: 0.125rem;
  }
}
