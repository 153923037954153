@use '../../reference';

.grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1, 1fr);

  @container (min-width: 30rem) {
    grid-template-columns: repeat(2, 1fr);
  }

  @container (min-width: 60rem) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.cell {
  container-type: inline-size;
}
