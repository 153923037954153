@use '../../reference';

.wrapper {
  position: absolute;

  @media (max-width: reference.$breakpoint-2xl) {
    display: none;
  }
}

.container {
  animation: spin 20s linear infinite;
  color: reference.$color-aqua;

  svg {
    height: auto;
    width: min(6vw, 180px);
  }
}

.position-left {
  left: 0;
  transform: translateX(-125%);
}

.position-right {
  right: 0;
  transform: translateX(125%);
}

.position-top {
  top: 0;
}

.position-bottom {
  bottom: reference.$space-3xl;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
