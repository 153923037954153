@use '../../reference';

.headline {
  align-items: baseline;
  color: reference.$color-grey-500;
  display: flex;
  font-size: reference.$font-size-heading-2;
  gap: reference.$space-m;
}

.subheadline {
  font-size: reference.$font-size-body-large;
}

.highlight {
  align-items: center;
  background-color: reference.$color-fuchsia-10;
  border-radius: reference.$border-radius;
  padding: reference.$space-l;
  position: relative;
  text-align: center;

  @include reference.flex-column(reference.$space-s);
}

.cupcakePositionBottom {
  gap: reference.$space-2xs;
  overflow: hidden;
}

.cupcakePositionTop {
  padding-block-start: reference.$space-xs;
}

.leadIn {
  color: reference.$color-primary;

  @include reference.type-lead-in;
}

.fuchsia {
  background-color: reference.$color-fuchsia-10;
}

.aqua {
  background-color: reference.$color-aqua-10;
}

.new-leaf {
  background-color: reference.$color-new-leaf-10;
}

.tangerine {
  background-color: reference.$color-tangerine-10;
}

.bottomCupcake {
  align-items: flex-end;
  display: flex;
  gap: reference.$space-m;

  & > svg {
    height: auto;

    &:nth-child(2) {
      margin-block-end: calc(reference.$space-m * -2);
      width: reference.$space-3xl;
    }

    &:not(:nth-child(2)) {
      width: reference.$space-xl;
    }
  }
}

.topCupcake {
  position: absolute;
  transform: translateY(-75%);

  & > svg {
    height: auto;
    width: reference.$space-l;
  }
}
