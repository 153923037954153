@use '../../reference';

.tip {
  --margin-inline: calc(var(--main-margin-inline) * -1);
  --padding: 1.5rem;
  --image-size: 82px;

  background-color: reference.$color-aqua-10;
  border-radius: 0;
  margin-block-start: calc(var(--image-size) / 2 + 1rem);
  margin-inline: var(--margin-inline);
  padding: var(--padding);
  position: relative;

  @container (min-width: 44rem) {
    --padding: 2.5rem;

    border-radius: reference.$border-radius;
    margin-block-start: 6rem;
  }

  ul li {
    margin-block: 0.25rem;

    &::marker {
      color: reference.$color-grey-500;
    }
  }

  ul:last-child {
    margin-block-end: 0;
  }
}

.blockquote {
  margin: 0;

  p:last-child {
    margin-block-end: 0;
  }
}

.header {
  text-align: center;
}

.picture {
  display: block;
  height: var(--image-size);
  left: calc(50% - var(--image-size) / 2);
  overflow: hidden;
  position: absolute;
  top: calc(var(--image-size) / -2);
  width: var(--image-size);
}

.image {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.heading {
  @include reference.type-tag;

  font-weight: reference.$font-weight-bold;
  margin-block-start: 2rem;
  margin-block-end: 0;
  text-transform: uppercase;

  @container (min-width: reference.$breakpoint-md) {
    margin-block-start: 1rem;
  }
}

.footer {
  font-size: reference.$font-size-filter;
  font-weight: reference.$font-weight-bold;
  text-align: center;

  p {
    margin-block-end: 0;
    margin-block-start: 2rem;
  }

  a {
    color: reference.$color-grey-400;
  }
}
