@use '../../reference';

.tag {
  @include reference.type-tag;

  background-color: reference.$color-grey-100;
  border-radius: 1rem;
  display: inline-block;
  padding-block: 0.25rem;
  padding-inline: 0.75rem;

  @include reference.dark-mode {
    background-color: reference.$color-grey-200;
  }
}
