@use '../../reference';

.videoWrapper {
  --graphic-initial-scale: 0.5;
  --graphic-horizontal-offset: calc(clamp(1rem, -2.0068rem + 6.6216vw, 3.125rem) * -1);
  --graphic-transform-duration: 2000ms;
  --graphic-width: clamp(1.875rem, -1.0811rem + 9.4595vw, 6.25rem);
  --push-amount: var(--space-3xl);

  background-color: reference.$color-aqua-10;
  border-radius: reference.$border-radius;
  box-sizing: border-box;
  margin-inline: calc(var(--push-amount) * -0.5);
  max-width: 95vw;
  padding-block: reference.$space-2xl;
  padding-inline: reference.$space-xl;
  position: relative;
  width: calc(100% + var(--push-amount));

  @media (max-width: reference.$breakpoint-sm) {
    padding-inline: reference.$space-l;
    width: 100%;
  }
}

.video {
  border-radius: reference.$border-radius;
  height: auto;
  width: 100%;
}

.controlButton {
  --button-size: 44px;

  align-items: center;
  appearance: none;
  background-color: reference.$color-white;
  border: 1px solid reference.$color-grey-200;
  border-radius: 50%;
  bottom: 1rem;
  color: reference.$color-grey-500;
  display: flex;
  height: var(--button-size);
  justify-content: center;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 1rem;
  text-align: center;
  transition: border-color reference.$transition-duration-short;
  width: var(--button-size);

  &:hover,
  &:focus-visible {
    border-color: reference.$color-grey-500;
  }
}

.leftImage {
  left: var(--graphic-horizontal-offset);
  position: absolute;
  top: 10%;
  transform: rotate(0)
    scale3d(
      var(--graphic-initial-scale),
      var(--graphic-initial-scale),
      var(--graphic-initial-scale)
    );
  transition: transform var(--graphic-transform-duration);
  width: var(--graphic-width);

  &.isVisible {
    transform: rotate(-15deg) scale3d(1, 1, 1);
  }

  svg {
    height: auto;
    width: 100%;
  }
}

.rightImage {
  right: var(--graphic-horizontal-offset);
  position: absolute;
  bottom: 40%;
  transform: rotate(0)
    scale3d(
      var(--graphic-initial-scale),
      var(--graphic-initial-scale),
      var(--graphic-initial-scale)
    );
  transition: transform calc(var(--graphic-transform-duration) * 1.5)
    calc(var(--graphic-transform-duration) * 0.5);
  width: var(--graphic-width);

  &.isVisible {
    transform: rotate(10deg) scale3d(1, 1, 1);
  }

  svg {
    height: auto;
    width: 100%;
  }
}
