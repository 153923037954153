@use '../../reference';

.link {
  align-items: center;
  display: inline-flex;
  font-size: 14px;
  font-weight: reference.$font-weight-semibold;
  gap: 0.5rem;
  text-decoration: none;
  text-transform: uppercase;

  &:focus,
  &:hover {
    color: reference.$color-primary;
  }

  svg {
    color: reference.$color-primary;
    transition: transform 250ms ease-in-out;
  }

  &:focus,
  &:hover {
    svg {
      transform: translateX(0.25rem);
    }
  }
}
