@use '../../reference';

.mobileFilterContainer {
  @media (min-width: 60rem) {
    display: none;
  }
}

.mobileButton {
  align-items: center;
  background-color: transparent;
  border-radius: 500px;
  border: 1px solid #d3d3d3;
  column-gap: 1rem;
  cursor: pointer;
  display: flex;
  font-size: reference.$font-size-filter;
  height: 66px;
  margin-block-end: 2rem;
  margin-inline: auto;
  padding-inline-start: 1.5rem;
  text-align: left;
}

.modalOverlay {
  background-color: reference.$color-grey-100;
  inset: 0;
  position: fixed;
}

.modal {
  height: 100%;
}

.dialog {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dialogContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  row-gap: 1rem;
  padding: 1.5rem;
}

.closeButton {
  align-self: flex-end;
  align-items: center;
  background-color: transparent;
  border: 0;
  column-gap: 0.5rem;
  display: flex;
  font-size: reference.$font-size-filter;
  font-weight: reference.$font-weight-semibold;
  margin-block-end: 1rem;

  svg {
    --size: 14px;

    color: reference.$color-primary;

    height: var(--size);
    width: var(--size);
  }
}

.dialogFooter {
  background-color: reference.$color-white;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
}

.clearAllButton {
  background-color: transparent;
  border: 0;
  color: reference.$color-primary;
  cursor: pointer;
  font-size: reference.$font-size-filter;
  font-weight: reference.$font-weight-semibold;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}

.searchButton {
  @include reference.type-button;

  background-color: reference.$color-grey-400;
  border: 1px solid reference.$color-grey-400;
  border-radius: 2.5rem;
  color: reference.$color-white;
  cursor: pointer;
  padding-block: 0.875rem;
  padding-inline: 1.5rem;
  transition-property: all;
  text-decoration: none;
  text-transform: none;

  &:focus-visible,
  &:hover {
    background-color: reference.$color-white;
    color: reference.$color-grey-400;
  }
}

.desktopFilterContainer {
  border: 1px solid #d3d3d3;
  border-radius: 500px;
  display: flex;
  height: 4rem;
  margin-block-end: 3.5rem;
  margin-inline: auto;
  max-width: 66rem;
  transition: background-color 125ms ease-in-out;

  &:has([data-pressed='true']) {
    background-color: reference.$color-grey-100;
  }

  @media (max-width: calc(60rem - 1px)) {
    display: none;
  }
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-inline: 6px;
}

.list {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
}

.item {
  container-type: inline-size;
}

.noResults {
  align-items: center;
  display: flex;
  flex-direction: column;

  &[hidden] {
    display: none;
  }
}

.noResultsHeading {
  @include reference.type-heading-4;

  margin-block-end: 0;
}

.button {
  @include reference.type-button;
}
