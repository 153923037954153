@use '../../reference';

.link {
  display: grid;
  grid-template-areas: 'stack';
  place-items: center;
  text-decoration: none;

  & > * {
    grid-area: stack;

    &:is(img) {
      aspect-ratio: 2 / 1;
      height: 100%;
      object-fit: cover;
      transition: filter reference.$transition-duration-long;
      width: 100%;
    }
  }

  &:hover,
  &:focus {
    & > *:is(img) {
      filter: brightness(0.5);
      transition: filter reference.$transition-duration-short;
    }

    .buttonLookingThing {
      opacity: 1;
    }
  }
}

.buttonLookingThing {
  @include reference.button;

  opacity: 0;
  transition:
    background-color reference.$transition-duration-short,
    color reference.$transition-duration-short,
    opacity reference.$transition-duration-short;
  z-index: 2;
}
