@use '../../reference';

.confettiButton {
  @include reference.type-button;

  align-items: center;
  background-color: reference.$color-primary;
  border: 1px solid reference.$color-primary;
  border-radius: 2.5rem;
  color: reference.$color-white;
  display: flex;
  gap: reference.$space-2xs;
  padding-block: 0.875rem;
  padding-inline: 1.5rem;
  position: relative;
  transition:
    background-color reference.$transition-duration-medium,
    border-color reference.$transition-duration-medium,
    color reference.$transition-duration-medium;
  text-decoration: none;
  z-index: 100;

  &:focus-visible,
  &:hover {
    background-color: reference.$color-white;
    color: reference.$color-primary;
  }
}

.confetti {
  height: 100%;
  inset: 0;
  position: absolute;
  width: 100%;
  pointer-events: none;
}

.confettiHolder {
  position: relative;
}
