@use '../../reference';

.accordion {
  background-color: reference.$color-white;
  box-shadow: reference.$box-shadow-1;
  border-radius: reference.$border-radius;
  font-size: 0.875rem;
}

.button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  font-size: reference.$font-size-filter;
  justify-content: space-between;
  padding-block: 1rem;
  padding-inline: 1.5rem;
  text-align: left;
  width: 100%;

  &[hidden] {
    display: none;
  }
}

.checkboxGroup {
  display: grid;
  gap: 1rem;
  padding-block: 1rem;
  padding-inline: 1.5rem;
}

.legend {
  font-weight: reference.$font-weight-semibold;
}

.label {
  align-items: center;
  border-radius: 3px;
  display: inline-flex;
  column-gap: 0.5rem;
  cursor: pointer;
  font-size: 0.875rem;

  &[data-focus-visible='true'] {
    outline: 2px solid;
    outline-offset: 3px;
  }
}

.checkbox {
  --size: 1rem;

  align-items: center;
  border-radius: 3px;
  border: 1px solid #c2c2c2;
  color: reference.$color-white;
  display: flex;
  height: var(--size);
  justify-content: center;
  overflow: hidden;
  width: var(--size);

  [data-selected='true'] & {
    background-color: reference.$color-primary;
    border-color: reference.$color-primary;
  }
}
