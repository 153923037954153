.carousel {
  --gap: 2rem;

  display: flex;
  gap: var(--gap);

  @container (max-width: calc(60rem - 1px)) {
    margin-block-end: 0.5rem;
    margin-inline: -2rem;
    overflow-x: scroll;
    padding-block: 1rem;
    padding-inline-end: 6rem;
    padding-inline-start: 2rem;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    & > * {
      min-width: calc(100vw - 6rem);
      scroll-snap-align: center;
    }
  }

  @container (min-width: 60rem) {
    flex-wrap: wrap;
    margin-block-end: 1.5rem;
  }
}

.masonry {
  @container (min-width: 60rem) {
    align-items: flex-start;
    flex-direction: column;
    max-height: 760px;

    .slide {
      max-height: min-content;
    }
  }

  @container (min-width: 82rem) {
    max-height: 760px;
  }
}

.slide {
  container-type: inline-size;
  flex: 1;
}

@container (min-width: 60rem) {
  .perRow-2 {
    min-width: calc(50% - var(--gap));
  }

  .perRow-3 {
    min-width: calc(33.333% - var(--gap));
  }

  .perRow-4 {
    min-width: calc(25% - var(--gap));
  }

  .perRow-5 {
    min-width: calc(20% - var(--gap));
  }
}

.container {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-block-end: 2rem;

  @container (min-width: 60rem) {
    display: none;
  }
}

.indicator {
  background-color: var(--color-grey-300);
  border-radius: 50%;
  height: 0.5rem;
  width: 0.5rem;
}

.current {
  background-color: var(--color-primary);
}
