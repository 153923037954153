@use '../../reference';

.email {
  --margin-inline: calc(var(--main-margin-inline) * -0.5);
  --window-border-radius: 0.5rem;

  background-color: reference.$color-grey-100;
  border-radius: var(--window-border-radius);
  box-shadow: reference.$box-shadow-1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-inline: var(--margin-inline);
  max-width: min(calc(100vw - var(--main-margin-inline)), 1000px);

  @media (min-width: #{reference.$breakpoint-lg}) {
    --margin-inline: calc(var(--main-margin-inline) * -2);
  }

  .message {
    color: reference.$color-grey-400;
    padding-block-start: reference.$space-l;

    & > *:first-child {
      margin-block-start: 0;
    }

    ol,
    ul {
      padding-inline-start: 1.5em;
      margin-block: 1em;

      li {
        list-style-type: revert;
        margin-block-start: 0;
        margin-block-end: 0.5em;
        min-height: unset;
        padding: 0;

        &::before {
          display: none;
        }

        &::marker {
          color: inherit;
        }
      }

      ol,
      ul {
        margin-block: 0.5em;
        padding-inline-start: 2em;

        &:first-child,
        &:last-child {
          margin-block: 0.5em;
        }

        li {
          list-style-type: initial;
        }
      }
    }

    ul {
      list-style-type: revert;
    }
  }
}

.body {
  background-color: reference.$color-white;
  border-radius: var(--window-border-radius);
  padding-block: reference.$space-m;
  padding-inline: reference.$space-s;
  margin: reference.$space-2xs;
}

.copyButton {
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  color: reference.$color-primary;
  display: flex;
  font-size: reference.$font-size-body;
  font-weight: reference.$font-weight-bold;
  gap: reference.$space-3xs;
  padding: 0;
}

.heading {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: reference.$space-xs;
  justify-content: space-between;
  margin-block-end: reference.$space-m;
  margin-block-start: reference.$space-l;

  @container (min-width: #{reference.$breakpoint-sm}) {
    flex-direction: row;
  }

  h2 {
    margin-block: 0;
  }
}

.header {
  background-color: #0078d4;
  border-top-left-radius: var(--window-border-radius);
  border-top-right-radius: var(--window-border-radius);
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  padding-block: reference.$space-s;
  padding-inline: reference.$space-xs;
}

.recipients {
  display: flex;
  flex-direction: column;
  gap: reference.$space-xs;
  width: 100%;
}

.fieldRow {
  display: grid;
  gap: reference.$space-2xs;
  grid-template-columns: 1fr;

  @container (min-width: #{reference.$breakpoint-sm}) {
    grid-template-columns: 1fr 8fr;
  }
}

.fieldName {
  color: reference.$color-grey-300;
}

.fieldValue {
  border-bottom: 1px solid reference.$color-grey-200;
  color: reference.$color-grey-500;
}

.windowControls {
  align-items: center;
  display: flex;
  gap: reference.$space-3xs;

  & > div {
    --button-size: 0.75rem;

    border-radius: 50%;
    height: var(--button-size);
    transition: filter reference.$transition-duration-short ease-in-out;
    width: var(--button-size);

    &:hover {
      filter: brightness(0.8);
    }

    &:nth-child(1) {
      background-color: #fe5f57;
    }

    &:nth-child(2) {
      background-color: #febb2e;
    }

    &:nth-child(3) {
      background-color: #29c83f;
    }
  }
}

.windowTitle {
  color: reference.$color-white;
  font-weight: reference.$font-weight-bold;
  line-height: 1;
  text-align: center;
}
