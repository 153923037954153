@use '../../reference';

$height: 1.5rem;
$width: 7rem;

.tabList {
  --border-radius: 50px;

  align-items: flex-start;
  border-radius: 50px;
  border: 1px solid #d9d9d9;
  display: inline-flex;
  padding: 0.25rem;
  position: relative;

  &::before {
    background-color: reference.$color-primary;
    border-radius: var(--border-radius);
    content: '';
    height: $height;
    padding: 0.25rem;
    pointer-events: none;
    position: absolute;
    text-align: center;
    transition: transform 125ms ease-in-out;
    width: $width;
    z-index: 1;
  }

  @for $i from 1 through 3 {
    &:has([data-selected='true']:nth-child(#{$i}))::before {
      transform: translateX($width * ($i - 1) + 0.5rem * ($i - 1));
    }
  }
}

.tab {
  @include reference.type-tab;

  border-radius: var(--border-radius);
  cursor: pointer;
  height: $height;
  line-height: $height;
  padding: 0.25rem;
  position: relative;
  text-align: center;
  transition: color 125ms ease-in-out;
  width: $width;
  z-index: 2;

  &:focus:not([data-selected='true']),
  &:hover:not([data-selected='true']) {
    background-color: reference.$color-grey-100;
  }

  &[data-selected='true'] {
    color: reference.$color-white;
  }
}
